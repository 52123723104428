import { Link } from "gatsby";
import React, { useState } from "react";

import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
} from "react-leaflet";
import L from "leaflet";

import logo from "../images/BearbatLogo.svg";

import {
  Box,
  Flex,
  Square,
  Center,
  Text,
  Icon,
  Spacer,
  Heading,
  GridItem,
  Image,
  Stack,
  HStack,
  SimpleGrid,
  Container,
  AspectRatio,
} from "@chakra-ui/react";
import { FiMapPin } from "react-icons/fi";

// const bearbatIcon = L.icon({
//   iconUrl: logo,
//   // shadowUrl: logo,
//   iconSize: [36, 36], // size of the icon
//   // shadowSize: [50, 64], // size of the shadow
//   iconAnchor: [36, 36], // point of the icon which will correspond to marker's location
//   // shadowAnchor: [4, 62], // the same for the shadow
//   popupAnchor: [-20, -45],
// });

const markersData = [
  {
    position: [51.555, -0.19],
    story: "I saw a thing",
  },
  {
    position: [51.506, -0.09],
    story: "I saw a flash",
  },
  {
    position: [50.73789, 4.22],
    story: "It might have been the manbearpig",
  },
];

const MapComponent = ({ scrolWheel = true }) => {
  function LocationMarker() {
    const [position, setPosition] = useState(null);
    const map = useMapEvents({
      click() {
        map.locate();
      },
      locationfound(e) {
        setPosition(e.latlng);
        map.flyTo(e.latlng, map.getZoom());
      },
    });

    return position === null ? null : (
      <Marker position={position}>
        <Popup>You are here</Popup>
      </Marker>
    );
  }

  return (
    <MapContainer
      style={{ height: "100%" }}
      center={[51.505, -0.09]}
      zoom={5}
      scrollWheelZoom={scrolWheel}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      <LocationMarker />

      {markersData.map((marker) => (
        <Marker
          // icon={bearbatIcon}

          position={marker.position}
        >
          <Popup>{marker.story}</Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default MapComponent;
